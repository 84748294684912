// 大会决议
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'JGMC',
                    label: '机构名称',
                    minWidth: 80,
                    showoverflowtooltip: true
                }, {
                    prop: 'SZXZQ',
                    label: '所在行政区',
                    minWidth: 100
                }, {
                    prop: 'JGJB',
                    label: '机构级别',
                    minWidth: 80,
                }, {
                    prop: 'GZRYZS',
                    label: '工作人员总数',
                    minWidth: 120
                }, {
                    prop: 'JGCLSJ',
                    label: '机构成立时间',
                    minWidth: 120,
                    showoverflowtooltip: true
                }, {
                    prop: 'ZYJFLY',
                    label: '主要经费来源',
                    minWidth: 120,
                    transList: {
                        0: '财政拨款',
                        1: '自收自支',
                        2: '差额拨款',
                        3: '其他'
                    },
                },
                SHZT,
                DJZT
            ],
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        selectData: [
            // {
            //     "label": "主要经费来源",
            //     "clearable": true,
            //     "placeholder": "请选择主要经费来源",
            //     "list": [
            //         { "ID": "财政拨款", "NAME": "财政拨款" },
            //         { "ID": "自收自支", "NAME": "自收自支" },
            //         { "ID": "差额拨款", "NAME": "差额拨款" },
            //         { "ID": "其他", "NAME": "其他" }
            //     ],
            //     "optionLabel": "NAME",
            //     "optionValue": "ID",
            //     "value": "ZYJFLY",
            //     "operateType": "search",
            //     "isShow": true,
            //     multiple: true
            // }
        ],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'searchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            }]
        }
    },
    snButton: {
        buttonData: [{
            btnType: 'button',
            operateType: 'buttonExport',
            showMethod: 'showExportBtn',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export default selfData;